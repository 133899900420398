.ReactFlagsSelect-module_filterBox__3m8EU {
	padding: 0 !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU > input {
	border: 1px solid;
	margin: 0 !important;
	padding-left: 14px !important;
	width: 100% !important;
}

.dark .ReactFlagsSelect-module_filterBox__3m8EU > input {
	background-color: rgb(55 65 81) !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
	padding: 4px 20px !important;
}

.custom-coutries-max-height > ul {
	max-height: 300px !important;
}

.dark .ReactFlagsSelect-module_flagsSelect__2pfa2 > button {
	color: rgb(249 250 251) !important;
}

.dark .ReactFlagsSelect-module_flagsSelect__2pfa2 > ul {
	background-color: rgb(55 65 81) !important;
}

.dark .ReactFlagsSelect-module_selectBtn__19wW7:after {
	border-top: 5px solid rgb(249 250 251) !important;
}

@keyframes outFacecamAnimation {
	0% {
	}
	100% {
		transform: translate(1920px, 0);
		opacity: 0;
	}
}

.outFacecam {
	animation: outFacecamAnimation 0.5s ease 0s 1 normal forwards;
}
@keyframes inFacecamAnimation {
	0% {
		transform: translate(-1920px, 0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.inFacecam {
	animation: inFacecamAnimation 0.5s ease 300ms 1 normal forwards;
}
